import {
  AddButton,
  Button,
  FormControl,
  FormProvider,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  formSubmit,
  useForm,
  makeClassificationOptions,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransNav } from 'i18n/trans/nav';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { InventoryModelFilter, InventoryModelValues } from 'dto/inventory';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import {
  getInventoryModels,
  setInventoryModelFilter,
} from 'features/inventoryModel/inventoryModelActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { Validity } from 'dto/common';
import { fetchInventoryClasses } from 'features/common/commonService';
import { inventoryModelFilterSelector } from 'features/inventoryModel/inventoryModelSelector';

export const InventoryModelSearchForm: FC = () => {
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [inventoryClassOptions, setInventoryClassOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const businessEntitiesOptions = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const inventoryControlLevelOptions = useClassificationOptions(
    ClassificationGroup.INVENTORY_CONTROL_LEVEL
  );
  const bucketNestingTypeOptions = useClassificationOptions(
    ClassificationGroup.BUCKET_NESTING_TYPE
  );
  const filter = useSelector(inventoryModelFilterSelector);

  const prepareValidity = (
    key: 'validityStart' | 'validityEnd',
    values?: Validity
  ) => {
    if (!values) {
      return {};
    }

    return {
      ...(values.to && {
        [`${key}To`]: formatDate(values.to, isoDateTimeFormat.split("'T'")[0]),
      }),
      ...(values.from && {
        [`${key}From`]: formatDate(
          values.from,
          isoDateTimeFormat.split("'T'")[0]
        ),
      }),
    };
  };

  const onSubmit = useCallback(
    ({ validityFrom, validityTo, ...rest }: Partial<InventoryModelFilter>) =>
      formSubmit(async () => {
        dispatch(
          getInventoryModels({
            ...rest,
            ...prepareValidity('validityStart', validityFrom),
            ...prepareValidity('validityEnd', validityTo),
            offset: 0,
          })
        );
      }),
    [dispatch]
  );

  const initialValues = useMemo<Partial<InventoryModelValues>>(
    () => ({
      ownerId: currentBusinessEntityId,
      ...filter,
    }),
    [filter, currentBusinessEntityId]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setInventoryModelFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit, values } = useForm<InventoryModelFilter>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const fetchInventoryClassOptions = useCallback(async () => {
    setInventoryClassOptions(
      makeClassificationOptions(await fetchInventoryClasses(values.ownerId))
    );
  }, [values.ownerId]);

  useEffect(() => {
    if (values.ownerId) {
      fetchInventoryClassOptions();
    }
  }, [fetchInventoryClassOptions, values.ownerId]);

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setInventoryModelFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm
      title={<TransNav i18nKey="inventoryModel" />}
      action={
        <AddButton component={Link} to="/configuration/model/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <TextField
                name="Name"
                label={<TransField i18nKey="modelName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="ownerId"
                label={<TransField i18nKey="owner" />}
                onChange={() => form.change('inventoryClassId')}
                options={businessEntitiesOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="nestingTypeId"
                label={<TransField i18nKey="nestingType" />}
                options={bucketNestingTypeOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="controlLevelId"
                label={<TransField i18nKey="controlLevel" />}
                options={inventoryControlLevelOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="inventoryClassId"
                label={<TransField i18nKey="inventoryClass" />}
                options={inventoryClassOptions}
                showEmptyOption
              />
            </Grid>
            <PeriodField
              from={{
                name: 'validityFrom',
                label: <TransField i18nKey="validFromRange" />,
                selectsRange: true,
                isClearable: true,
              }}
              to={{
                name: 'validityTo',
                label: <TransField i18nKey="validToRange" />,
                selectsRange: true,
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <RadioGroupField
                name="isActive"
                label={<TransField i18nKey="isActive" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
