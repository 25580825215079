import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  deleteInventoryBuckets: (
    <Trans
      i18nKey="modal.title.deleteInventoryBuckets"
      defaults="Delete inventory buckets"
    />
  ),
  inventoryBucketsDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryBucketsDeletionDescription"
      defaults="Are you sure you want to delete selected inventory buckets? You can't undo this"
    />
  ),
  deleteInventoryModels: (
    <Trans
      i18nKey="modal.title.deleteInventoryModels"
      defaults="Delete inventory models"
    />
  ),
  inventoryModelsDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryModelsDeletionDescription"
      defaults="Are you sure you want to delete selected inventory models? You can't undo this"
    />
  ),
  deleteInventoryModel: (
    <Trans
      i18nKey="modal.title.deleteInventoryModel"
      defaults="Delete inventory model"
    />
  ),
  inventoryModelDeletionDescription: (
    <Trans
      i18nKey="modal.title.inventoryModelDeletionDescription"
      defaults="Are you sure you want to delete inventory model {{name}}? You can't undo this"
    />
  ),
});
