import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  PeriodField,
  RadioGroupField,
  SelectField,
  TextField,
  Tooltip,
  formSubmit,
  useForm,
  useModal,
  makeClassificationOptions,
} from '@fleet/shared';
import { useHistory, useParams } from 'react-router-dom';
import { InventoryModelValues } from 'dto/inventory';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import {
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import {
  clearCurrentInventoryModel,
  createOrUpdateInventoryModel,
  deleteInventoryModel,
  getInventoryModel,
  getInventoryModels,
} from 'features/inventoryModel/inventoryModelActions';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import {
  currentInventoryModelSelector,
  inventoryModelFilterSelector,
} from 'features/inventoryModel/inventoryModelSelector';
import { InventoryModelAccordion } from 'routes/InventoryConfiguration/InventoryModel/Accordion/InventoryModelAccordion';
import { TransModal } from 'i18n/trans/modal';
import { fetchInventoryClasses } from 'features/common/commonService';

export const InventoryModelDetails: FC = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const currentInventoryModel = useSelector(currentInventoryModelSelector);
  const filter = useSelector(inventoryModelFilterSelector);
  const [inventoryClassOptions, setInventoryClassOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const businessEntitiesOptions = useClassificationOptions(
    ClassificationGroup.BUSINESS_ENTITY
  );
  const inventoryControlLevelOptions = useClassificationOptions(
    ClassificationGroup.INVENTORY_CONTROL_LEVEL
  );
  const bucketNestingTypeOptions = useClassificationOptions(
    ClassificationGroup.BUCKET_NESTING_TYPE
  );
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    if (id) {
      dispatch(getInventoryModel(id!));
    }

    return () => {
      dispatch(clearCurrentInventoryModel());
    };
  }, [id, dispatch]);

  const handleGoBack = useCallback(() => {
    history.replace('/configuration/model');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (event, reason) => {
      if (reason === 'close') {
        handleGoBack();
      }
    },
    [handleGoBack]
  );

  const initialValues = useMemo<Partial<InventoryModelValues>>(() => {
    if (!currentInventoryModel) {
      return {
        isActive: false,
        areControlsBidirectional: false,
        ownerId: currentBusinessEntityId,
      };
    }

    const { inventoryClass, owner, nestingType, controlLevel, ...rest } =
      currentInventoryModel;

    return {
      ...rest,
      inventoryClassId: inventoryClass.id,
      ownerId: owner.id,
      nestingTypeId: nestingType.id,
      controlLevelId: controlLevel.id,
    };
  }, [currentBusinessEntityId, currentInventoryModel]);

  const onSubmit = useCallback(
    async ({ validity, ...values }: InventoryModelValues) =>
      formSubmit(async () => {
        delete values.nestingTypeId;
        const data = await dispatch(
          createOrUpdateInventoryModel({
            ...values,
            validity: {
              from: formatDate(
                validity.from,
                isoDateTimeFormat.split("'T'")[0]
              ),
              to: formatDate(validity.to, isoDateTimeFormat.split("'T'")[0]),
            },
          })
        ).unwrap();
        alert.success(
          <TransAlert
            i18nKey={
              values.id ? 'inventoryModelUpdated' : 'inventoryModelCreated'
            }
          />
        );

        if (!values.id) {
          history.push(`/configuration/model/edit/${data.id}`);
        } else {
          dispatch(getInventoryModel(data.id));
        }

        dispatch(getInventoryModels(filter));
      }),
    [alert, history, dispatch, filter]
  );

  const { form, handleSubmit, dirty, submitting, values } =
    useForm<InventoryModelValues>({
      initialValues,
      onSubmit,
      subscription: { dirty: true, submitting: true, values: true },
    });

  const fetchInventoryClassOptions = useCallback(async () => {
    setInventoryClassOptions(
      makeClassificationOptions(await fetchInventoryClasses(values.ownerId))
    );
  }, [values.ownerId]);

  useEffect(() => {
    if (values.ownerId) {
      fetchInventoryClassOptions();
    }
  }, [fetchInventoryClassOptions, values.ownerId]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteInventoryModel(id!)).unwrap();
    alert.success(<TransAlert i18nKey="inventoryModelDeleted" />);
    handleGoBack();
    await dispatch(getInventoryModels(filter)).unwrap();
  }, [dispatch, id, alert, handleGoBack, filter]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <>
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <CardHeader
              isLight
              title={
                <Typography variant="subtitle">
                  {id ? (
                    currentInventoryModel?.name
                  ) : (
                    <TransTitle i18nKey="newInventoryModel" />
                  )}
                </Typography>
              }
              action={
                <>
                  {id && (
                    <>
                      <Button
                        variant="text"
                        color="error"
                        startIcon={<Icon name="trash" />}
                        onClick={onOpen}
                      >
                        <TransButton i18nKey="delete" />
                      </Button>
                      <ConfirmDeleteModal
                        handleDelete={handleDelete}
                        title={<TransModal i18nKey="deleteInventoryModel" />}
                        description={
                          <TransModal
                            i18nKey="inventoryModelDeletionDescription"
                            values={{ name: currentInventoryModel?.name }}
                          />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      />
                    </>
                  )}
                  <IconButton aria-label="close" onClick={handleGoBack}>
                    <Tooltip
                      content={<TransButton i18nKey="close" />}
                      delay={500}
                    >
                      <Icon name="close" size={24} />
                    </Tooltip>
                  </IconButton>
                </>
              }
            />
            <CardContent>
              <Grid container columns={4} spacing={2}>
                <Grid item xs={1}>
                  <TextField
                    name="name"
                    label={<TransField i18nKey="modelName" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="ownerId"
                    label={<TransField i18nKey="owner" />}
                    onChange={() => form.change('inventoryClassId')}
                    options={businessEntitiesOptions}
                    disabled={!!id}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="nestingTypeId"
                    label={<TransField i18nKey="nestingType" />}
                    options={bucketNestingTypeOptions}
                    disabled
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="controlLevelId"
                    label={<TransField i18nKey="controlLevel" />}
                    options={inventoryControlLevelOptions}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="inventoryClassId"
                    label={<TransField i18nKey="inventoryClass" />}
                    options={inventoryClassOptions}
                    disabled={(currentInventoryModel?.buckets || []).length > 0}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="areControlsBidirectional"
                    label={<TransField i18nKey="controlsBidirectional" />}
                    options="BOOL_ONLY"
                    inline
                  />
                </Grid>
                <PeriodField
                  from={{
                    name: 'validity.from',
                    label: <TransField i18nKey="validFrom" />,
                    required: true,
                  }}
                  to={{
                    name: 'validity.to',
                    label: <TransField i18nKey="validTo" />,
                    required: true,
                  }}
                />
                {id && (
                  <Grid item xs={1}>
                    <RadioGroupField
                      name="isActive"
                      label={<TransField i18nKey="active" />}
                      options="BOOL_ONLY"
                      inline
                    />
                  </Grid>
                )}

                <Grid item xs="auto" sx={{ ml: 'auto' }}>
                  <Stack direction="row" flexWrap="nowrap">
                    <FormControl label="&nbsp;">
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={handleReset}
                        disabled={!dirty}
                      >
                        <TransButton i18nKey="resetChanges" />
                      </Button>
                    </FormControl>
                    <FormControl label="&nbsp;">
                      <Button
                        type="submit"
                        variant="contained"
                        icon="check"
                        disabled={submitting}
                      >
                        <TransButton i18nKey="save" />
                      </Button>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </FormProvider>

        <InventoryModelAccordion />
      </>
    </DrawerForm>
  );
};
